@import-normalize; /* bring in normalize.css styles */
$red: #e64242;
$red-hover: #ca3434;
$orange: #f5761b;
$orange-hover: #d65b13;
$yellow: #ebb800;
$yellow-hover: #d19e11;
$green: #2fbe9b;
$green-hover: #1ea896;
$blue: #36a1f7;
$blue-hover: #3a8fd4;
$purple: #924bcc;
$purple-hover: #8032c0;
$brown: #944f26;
$brown-hover: #793f1e;
$white: #ffffff;
$white-hover: #dadada;
$black: #171717;
$black-hover: #2c2c2c;
$gray: #686a6ba2;
$gray-hover: #545657a2;

$divider: #9493936e;
$key-color: #cdd0d181;
$key-hover-color: #aeb0b181;
$tile-color: #a4a6a779;
$bar-color: #828485cc;
$message: #ffffff;
$message-color: #cdd0d181;

$light-mode-text: #383838;
$light-mode-bg: #ffffff;
$dark-mode-text: #ffffff;
$dark-mode-bg: #202020;

$themes: (
  light: (
    app-bg: $light-mode-bg,
    text-color: $light-mode-text,
  ),
  dark: (
    app-bg: $dark-mode-bg,
    text-color: $dark-mode-text,
  ),
);

html,
body {
  width: 100vw;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App:focus {
  outline: none;
}

.nav-bar {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $divider;
  padding: 0px 10px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.title {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 32px;
  font-weight: bold;
  line-height: 100%;
  text-align: center;
  letter-spacing: 2px;
}

.nav-btn-container-left,
.nav-btn-container-right {
  display: flex;
  align-items: center;
  width: 10%;
}

.nav-btn-container-left {
  justify-content: flex-start;
}

.nav-btn-container-right {
  justify-content: flex-end;
}

.nav-btn {
  border: 0px;
  margin: 0px 4px;
  background-color: transparent;
  font-size: 1.2rem;
  cursor: pointer;
}

.game {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  flex-shrink: 1;
  flex-grow: 1;
  flex: 1;
  box-sizing: border-box;
}

.transform {
  animation: transform 0.1s ease-in;
}

@keyframes transform {
  0% {
    transform: scale(95%, 95%);
  }
  60% {
    transform: scale(105%, 105%);
  }
  100% {
    transform: scale(100%, 100%);
  }
}

.bounce {
  animation: bounce 1s ease;
  transform-origin: center bottom;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-18px);
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.gameboard {
  margin-top: 3vh;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tile {
  width: 70px;
  height: 70px;
  margin: 0px 4px 8px 4px;
  border: 2px solid $tile-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vh;
  box-sizing: border-box;
  font-weight: bold;
  color: $light-mode-text;
  pointer-events: none;
}

.invalid {
  border: 2px solid #eb4b4bb2;
}

.keyboard {
  width: 100%;
  margin: auto;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.keyboard .row {
  width: 100%;
  margin: auto;
  justify-content: center;
}

.key {
  height: 7.5vh;
  margin: 3px;
  background-color: $key-color;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.1s;
  font-size: max(1.7vh, 13px);
  font-weight: bold;
  cursor: pointer;
  border: 0px;
  flex-shrink: 1;
}

.fa-delete-left {
  font-size: 2.5vh;
}

@media only screen and (min-width: 768px) {
  .key:hover {
    background: $key-hover-color;
  }

  .tile {
    font-size: 4vh;
  }

  .App {
    height: 100vh;
  }

  .game {
    justify-content: space-between;
  }
}

.ReactModal__Content {
  width: min(500px, 100vw);
  height: fit-content;
  margin: auto;
  animation: fadeIn 0.15s ease-in;
  overflow: scroll;
  max-height: 100%;
}

.ReactModal__Content--before-close {
  animation: fadeOut 0.15s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 100%;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 100%;
    transform: translateY(0);
  }
  to {
    opacity: 0%;
    transform: translateY(20px);
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

h3 {
  display: flex;
  justify-content: center;
  margin-left: auto;
}

h4 {
  margin-top: 0;
}

.close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}

.stats-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stats-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  margin: auto;
}

.stat-container {
  width: 22%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 4% auto;
}

.stat {
  font-size: 2.5rem;
}

.stat-label {
  text-align: center;
  word-wrap: break-word;
}

.distribution {
  width: 80%;
  margin: 1rem auto;
}

.bar-wrapper {
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  align-items: center;
  margin: 3px 0;
}

.bar-label {
  flex-shrink: 0;
  width: 12px;
}

.bar {
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 5px;
  margin-left: 4px;
  line-height: 120%;
  box-sizing: border-box;
  min-width: 20px;
  font-size: 1rem;
  height: 1.2rem;
  font-weight: bold;
}

.countdown-share-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.countdown-container {
  border-right: 1px solid $divider;
}

.countdown-container,
.share-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 50%;
  font-weight: bold;
}

.countdown {
  font-size: min(4.5vh, 8vw);
}

.share {
  width: 75%;
  height: 50%;
  font-size: 1.3rem;
  border: 0px;
  cursor: pointer;
  transition-duration: 0.1s;
  border-radius: 4px;
  font-weight: bold;
}

.message {
  display: flex;
  padding: 0px 16px;
  height: 6vh;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  color: $light-mode-text;
  background-color: $message-color;
  font-weight: bold;
  border-radius: 8px;
  z-index: 2;
}

.message--fade {
  animation: fadeOutMessage 0.25s ease;
}

@keyframes fadeOutMessage {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

@media only screen and (max-height: 800px) {
  .message {
    position: absolute;
    top: 7vh;
    margin: 1rem;
  }
}

.rules-modal,
.options-modal {
  display: flex;
  flex-direction: column;
}

.rules-modal,
.options-modal,
.stats-modal {
  padding: 5%;
}

.rules-modal p {
  margin-top: 0px;
}

hr {
  width: 100%;
  background-color: $divider;
  border: 0px;
  height: 1px;
  margin: 18px 0px;
}

.option-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.palette-label-container,
.label-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  flex-shrink: 1;
}

.palette-label-container {
  width: 100%;
}

.option-label {
  font-size: 1rem;
}

.option-description {
  word-wrap: break-word;
  font-size: 0.8rem;
  color: gray;
}

.palette-list {
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.palette-list-label {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.palettes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
}

textarea {
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
}

.import-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.export-btn,
.import-btn,
.reset-btn {
  width: 20%;
  height: 2rem;
  font-size: 1rem;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  transition-duration: 0.1s;
}

textarea {
  margin: 10px 0px;
}

.reset-btn {
  background-color: $gray;
}

.--dark-mode {
  background: $dark-mode-bg;
  color: $dark-mode-text;
}

.--tile-dark-mode {
  color: $dark-mode-text;
}

.--nav-btn-dark-mode {
  color: $dark-mode-text;
}

.--title-dark-mode {
  color: white;
}

.--message-dark-mode {
  background-color: #ffffff;
}

.red {
  color: white;
  background-color: $red;
  border: 0;
  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: $red-hover;
    }
  }
}

.orange {
  color: white;
  background-color: $orange;
  border: 0;
  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: $orange-hover;
    }
  }
}

.yellow {
  color: white;
  background-color: $yellow;
  border: 0;
  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: $yellow-hover;
    }
  }
}

.green {
  color: white;
  background-color: $green;
  border: 0;
  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: $green-hover;
    }
  }
}

.blue {
  color: white;
  background-color: $blue;
  border: 0;
  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: $blue-hover;
    }
  }
}

.purple {
  color: white;
  background-color: $purple;
  border: 0;
  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: $purple-hover;
    }
  }
}

.brown {
  color: white;
  background-color: $brown;
  border: 0;
  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: $brown-hover;
    }
  }
}

.white {
  color: $light-mode-text;
  background-color: $white;
  border: 0;
  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: $white-hover;
    }
  }
}

.black {
  color: white;
  background-color: $black;
  border: 0;
  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: $black-hover;
    }
  }
}

.gray {
  color: white;
  background-color: $gray;
  border: 0;
  @media only screen and (min-width: 768px) {
    &:hover {
      background-color: $gray-hover;
    }
  }
}

.palette-box {
  margin: 2px;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: 1px solid $divider;
  cursor: pointer;
}

.unselected {
  border-radius: 4px;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  border-radius: 4px;
  border: 2px solid $divider;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .keyboard {
    margin: 0;
  }

  .gameboard {
    margin: 2.9vh;
    padding-top: 8px;
  }

  .game {
    justify-content: space-between;
  }
}
